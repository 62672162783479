* {
  outline: none;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Square";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
  box-sizing: border-box;
  color: #fcfcfc;

  margin: 0;
  font-size: 0.75rem;
  // background: radial-gradient(78.85% 78.85% at 50% 0%, rgba(129, 108, 255, 0.66) 0%, rgba(151, 53, 118, 0) 100%),
  //   #000132 !important;
  font-family: Square;
  font-weight: 400;
  line-height: 1;
  /* background-color: rgba(8, 15, 53, 1); */
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  font-family: "Square";
  overflow: hidden;
  transition: all ease 0.33ms;
  // background-image: url("./assets/background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background: radial-gradient(78.85% 78.85% at 50% 0%, rgb(108 214 255 / 66%) 0%, rgba(151, 53, 118, 0) 100%), #001432;
}

.MuiDrawer-paperAnchorDockedLeft {
  border-right: 0 !important;
}

.ohm-card {
  max-width: 833px;
  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;
    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-display {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin: 12px 0px;
  }
  .conversion-close {
    display: block;
    justify-content: center;
    flex-direction: row;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;
    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.ohm-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//   -webkit-appearance: none !important;
// }

input[type="number"] {
  -moz-appearance: textfield !important;
}

a:hover svg {
  color: inherit;
}
