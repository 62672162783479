#nftmint-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .nftmint-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .nftmint-table-panel,
  .nftmint-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .nftmint-tab-panel,
  .mobile .nftmint-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .madao-card .header {
    min-height: 3em;
  }
  .madao-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
  .nftmint-note {
    text-align: center;
    font-style: italic;
  }

  div.metric {
    margin: 3px;
    min-width: 140px;
    width: 30%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.migrate-smadao-button {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-smadao-button.complete {
  color: #35ee66 !important;
}

.nftmint-top-metrics {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  h5 {
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
  }
  .nftmint-apy,
  .nftmint-tvl,
  .nftmint-index {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
  }
}

.mobile .nftmint-top-metrics {
  .nftmint-apy,
  .nftmint-tvl,
  .nftmint-index {
    align-items: flex-start;
  }
}

.madao-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}

.nftmint-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 10px;
}

.nftmint-action-row {
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-bottom: 1rem;
}

.mobile .nftmint-action-row {
  justify-content: center;
}

.nftmint-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .nftmint-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

.madao-input {
  max-width: 542px;
  width: 100%;
  margin: 5px !important;
}

.nftmint-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
}

.mobile .nftmint-button {
  width: 100% !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
}

.staking-area {
  margin: 13px 10px 13px 10px;
}

.nftmint-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.nftmint-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.tablet .nftmint-pool,
.mobile .nftmint-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .madao-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .nftmint-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.nftmint-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}



.smaller {
  .madao-card {
    width: 97%;
  }
  .hero-metrics .madao-card {
    > .MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.app:not(.mobile) .runway {
  margin: 0 auto !important;
}

.very-small {
  .hero-metrics .madao-card {
    > .MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}
